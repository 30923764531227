<script>
    import { required, minLength, numeric } from "vuelidate/lib/validators";
    import validationMessages from '@/components/validations'
    import Ticketing from '@/services/Ticketing';
    import Customers from '@/services/Customers';

    export default {
        components:{
            validationMessages
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                customerNumber: '',
                submitted: false,
                tryingToEdit: false,
                showErrorMessage: false,
                showModal: false,
                ticketingUserData: [],
                send_email: true
            };
        },
        validations: {
            customerNumber: {
                required
            },
        },
        created() {
            
        },
        methods: {

            async getTicketingUser(){
                this.tryingToEdit = true
                this.submitted = true
                this.ticketingUserData = []
                this.showErrorMessage = false
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false
                    return;
                }

                await Ticketing.getUserByCrmId(this.customerNumber)
                        .then((response) => {
                            this.ticketingUserData = response.data.data
                            if(Object.keys(this.ticketingUserData).length == 0){
                                this.showErrorMessage = true
                                return;
                            }
                        })
                        .catch(error => {
                            this.ticketingUserData = []
                            this.error = error.response.data.error ? error.response.data.error : "";
                        })
                        .finally(() => {
                            this.tryingToEdit = false
                        })

            },

            async createNewUser(){
                this.tryingToEdit = true
                await Customers.createCustomerFromTicketingData({
                            customerNumber: this.customerNumber,
                            send_email: this.send_email == true ? 'Y' : 'N',
                        })
                        .then((response) => {
                            this.successmsg("Customer Created")
                            this.closeModal()
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "Creation Failed !";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.tryingToEdit = false
                        })
            },

            closeModal() {
                this.customerNumber = '',
                this.submitted=  false,
                this.tryingToEdit= false,
                this.showErrorMessage= false,
                this.ticketingUserData= [],
                this.send_email= true,
                this.showModal = false
            },
        }
    };
</script>

<template>
    <b-modal v-model="showModal" id="add_new_customer_from_ticketing" title="Create New Customer" title-class="font-18" size="lg" >
        <form @submit.prevent="getTicketingUser">
            <div class="row">
                <div class="col-9">
                    <b-form-group  label-for="customerNumber"  class="mb-3">
                        <b-form-input
                                v-model.trim="customerNumber"
                                type="text"
                                id="customerNumber"
                                aria-describedby="firstname-feedback"
                                placeholder="Ticketing Number"
                                :class="{
                                  'is-invalid': submitted && $v.customerNumber.$error,
                                }"
                        >
                        </b-form-input>
                        <validationMessages v-if="submitted" :fieldName="'Ticketing Number'" :validationName="$v.customerNumber"></validationMessages>
                    </b-form-group>
                </div>
                <div class="col-3">
                    <b-button variant="primary" @click="getTicketingUser" :disabled="tryingToEdit">
                        <b-spinner v-show="tryingToEdit" small></b-spinner>
                        Pull User
                    </b-button>
                </div>
                <div class="col-12" v-show="showErrorMessage">
                    <b-alert variant="danger" show >Ticketing user not found. Please try again !</b-alert>
                </div>
                
            </div>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-if="Object.keys(this.ticketingUserData).length">
            <div class="row">
                <div class="col-sm-12 mt-3 mb-3">
                    <h4>User Data:</h4>
                </div>
                <div class="col-sm-6">
                    <p class="font-size-16 mb-2"><span class="text-strong">Ticketing ID:</span> {{ticketingUserData.ticketing_id}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Ticketing Number:</span> {{ticketingUserData.customerNumber}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Customer ID:</span> {{ticketingUserData.customer_id}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Email:</span> {{ticketingUserData.email}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">First Name:</span> {{ticketingUserData.first_name}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Middle Name:</span> {{ticketingUserData.middle_name}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Last Name:</span> {{ticketingUserData.last_name}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Date of Birth:</span> {{ticketingUserData.dob}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Gender:</span> {{ticketingUserData.gender}}</p>
                    
                </div>
                <div class="col-sm-6">
                    <p class="font-size-16 mb-2"><span class="text-strong">Phone:</span> {{ticketingUserData.phone}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Street:</span> {{ticketingUserData.street}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Street Number:</span> {{ticketingUserData.street_number}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">City:</span> {{ticketingUserData.city}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Postal:</span> {{ticketingUserData.postal}}</p>
                    <p class="font-size-16 mb-2"><span class="text-strong">Country:</span> {{ticketingUserData.country}}</p>
                </div>

                <hr>

                <b-form-checkbox-group>
                    <div class="form-check form-switch form-switch-lg mt-5" >
                        <input v-model="send_email"
                            type="checkbox"
                            class="form-check-input"
                            id="send_email"
                        />
                        <label class="form-check-label" for="send_email">Sent Email to Customer</label>
                    </div>
                </b-form-checkbox-group>
                <b-button class="mt-4" variant="primary" @click="createNewUser" :disabled="tryingToEdit">
                    <b-spinner v-show="tryingToEdit" small></b-spinner>
                    Create New User
                </b-button>
                
            </div>
            
        </template>


        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
        </template>
    </b-modal>
</template>