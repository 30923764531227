<script>
    import * as Vue2Leaflet from 'vue2-leaflet'
    import { latLng, Icon, icon } from 'leaflet'
    import iconUrl from '@/assets/images/leaflet/marker-icon.png'
    import shadowUrl from '@/assets/images/leaflet/marker-shadow.png'
    import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

    /**
     * Stat component
     */
    export default {
        components: {
            'v-map': Vue2Leaflet.LMap,
            'v-tilelayer': Vue2Leaflet.LTileLayer,
            'v-icondefault': Vue2Leaflet.LIconDefault,
            'v-marker': Vue2Leaflet.LMarker,
            'v-popup': Vue2Leaflet.LPopup,
            'v-marker-cluster': Vue2LeafletMarkerCluster,
        },
        props: {
            customerData: {
                type: Array,
                default: null
            },
            showMapBool:{
                type:Boolean
            }
        },
        watch: {
            customerData: {
                handler: function(val, oldVal) {
                    this.refreshMapData();
                    if(this.showMapBool){
                        this.resetZoom();
                    }
                },
                deep: true
            },
            showMapBool: {
                handler: function (val, oldVal) {
                    this.disableZoom();
                }
            }
        },
        data () {
            return {
                locations: [],
                notValidlocations: [],
                icon: this.customicon(iconUrl, shadowUrl),
                clusterOptions: {},
                initialZoom: 5,
                initialLocation: latLng(52.370837255463684, 4.889450937994813)
            }
        },
        mounted() {
            setTimeout(() => {
                this.$nextTick(() =>{
                    this.clusterOptions = { disableClusteringAtZoom: 11 }
                });
            }, 5000);
        },
        methods : {
            customicon(iconUrl, shadowUrl){
                return icon(Object.assign({},
                    Icon.Default.prototype.options,
                    {iconUrl, shadowUrl}
                ))
            },
            refreshMapData(){
                this.locations = [];
                this.notValidlocations = [];

                this.customerData.forEach((value) => {
                    if(value.google_lat && value.google_lng ){
                        let customerLoc = '<strong>'+value.first_name+' '+value.middle_name+' '+value.last_name+'</strong>';
                        customerLoc += '<br>'+value.address_line1 + ' ' + value.address_line2 + ' ' + value.address_line3 + ', ' + value.address_postal + ' ' + value.address_city + ', ' + value.address_country;
                        customerLoc += '<br><a href="/customers/profile/'+value.customer_id +'">Customer Profile</a>';
                        this.locations.push({
                            id: value.customer_id,
                            latlng: latLng(value.google_lat, value.google_lng),
                            text: customerLoc
                        })
                    }else{
                        this.notValidlocations.push({id: value.customer_id});
                    }
                });

            },
            resetZoom() {

                const map = this.$refs.mapRef.mapObject;
                map.scrollWheelZoom.disable();
                map.setView(this.initialLocation, this.initialZoom);
            },
            disableZoom(){
                this.$nextTick(() =>{
                    const map = this.$refs.mapRef.mapObject;
                    map.scrollWheelZoom.disable();
                });

            },
            click: (e) => console.log("clusterclick", e),
            ready: (e) => console.log('ready', e),
        }
    };
</script>


<template>
    <div>
        <v-map :zoom="initialZoom" :center="initialLocation" v-if="showMapBool" ref="mapRef" style="height: 500px">
            <v-icondefault></v-icondefault>
            <v-tilelayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"></v-tilelayer>
            <v-marker-cluster :options="clusterOptions" @clusterclick="click()" @ready="ready" ref="clusterRef">
                <v-marker v-for="l in locations" :key="l.id" :lat-lng="l.latlng" :icon="icon">
                    <v-popup :content="l.text"></v-popup>
                </v-marker>
            </v-marker-cluster>
        </v-map>
        <div class="col mt-3">
            <p class="text-strong font-size-16">Total Users: {{customerData.length}}</p>
            <p class="text-strong font-size-16">Number of users not shown on the map: {{notValidlocations.length}}</p>
        </div>
    </div>
</template>



<style>
    @import "~leaflet/dist/leaflet.css";
    @import "~leaflet.markercluster/dist/MarkerCluster.css";
    @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>